import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";
import { isEmpty } from "lodash-es";

export const useUrlBindingItemFilters = () => {
  const router = useRouter()
  const route = useRoute()
  const initialRouteName = route.name
  if (!initialRouteName) throw Error("In order to use useUrlBindingItemFilters, you must specify a route name")

  const filter = ref({})

  filter.value = JSON.parse(route.query?.filter ?? null)

  /* URL --> JS object */
  watch(route, (r) => {
    if (r.name === initialRouteName) {
      filter.value = JSON.parse(r.query?.filter ?? null)
    }
  })

  /* JS object --> URL */
  watch(() => filter, (f) => {
    const to = {
      path: route.path,
      hash: route.hash ? '#' : '', // Иначе vue router не перезагружает страницу
      query: {
        ...route.query,
        filter: JSON.stringify(f.value)
      }
    }

    if (isEmpty(f.value)) {
      delete to.query.filter
    }

    router.push(to)
  }, { deep: true })


  return {
    filter,
  }
}
