import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "body"
};
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import { ref, onMounted, reactive } from "vue";
import { useBackend } from "@/features/api/useBackend.js";
import { useNotification } from "@/features/notifications/useNotification.js";
export default {
  __name: 'PageMailings',

  setup(__props) {
    const backend = useBackend();
    const loading = ref(false);
    const form = reactive({
      email: false
    });

    const updateFromBackend = async () => {
      loading.value = true;
      const res = await backend.api('mailings/get');
      form.email = res.data.result.email;
      loading.value = false;
    };

    onMounted(() => {
      updateFromBackend();
    });

    const onEmailUpdate = async () => {
      const newValue = !form.email;
      loading.value = true;

      try {
        await backend.api('mailings/update', {
          email: newValue
        });
        await updateFromBackend();
        useNotification().show({
          type: 'success',
          title: 'Настройки рассылки были обновлены'
        });
      } finally {
        loading.value = false;
      }
    };

    return (_ctx, _cache) => {
      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_createVNode(PageHeader), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
          modelValue: form,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(form) ? form.value = $event : null)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "E-mail рассылка"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: form.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.email = $event),
              loading: loading.value,
              beforeChange: onEmailUpdate
            }, null, 8, ["modelValue", "loading"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])])]),
        _: 1
      });
    };
  }

};