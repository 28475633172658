import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";
import { isEmpty } from "lodash-es";

export const useUrlBindingItemSorts = () => {
  const router = useRouter()
  const route = useRoute()
  const initialRouteName = route.name
  if (!initialRouteName) throw Error("In order to use useUrlBindingItemSorts, you must specify a route name")

  /** bitrix style */
  const sort = ref({})

  sort.value = JSON.parse(route.query?.sort ?? null)

  /* URL --> JS object */
  watch(route, (r) => {
    if (r.name === initialRouteName) {
      sort.value = JSON.parse(r.query?.sort ?? null)
    }
  })

  /* JS object --> URL */
  watch(() => sort, (s) => {
    const to = {
      path: route.path,
      hash: route.hash ? '#' : '', // Иначе vue router не перезагружает страницу
      query: {
        ...route.query,
        sort: JSON.stringify(s.value)
      }
    }

    if (isEmpty(s.value)) {
      delete to.query.sort
    }

    router.push(to)
  }, { deep: true })


  return {
    sort,
  }
}
