import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6a154141"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "banners"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 1,
  class: "banners-slider__image-box"
};
const _hoisted_6 = ["src"];
import { onMounted, ref, computed } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { CONFIG } from "@/config.js";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
export default {
  __name: 'BannersSlider',

  setup(__props) {
    const banners = ref([]); // TODO: Отключение уведомлений об ошибке временно, пока на бэкенде не готово это АПИ.

    const entityBanner = useEntity('Banner', {
      showNotificationOnError: false
    });
    onMounted(async () => {
      const res = await entityBanner.list();
      banners.value = res.result;
    });
    const bannersSlider = ref('');

    const setBannersSlider = swiper => {
      bannersSlider.value = swiper;
    };

    const isOneSlide = computed(() => banners.value.length === 1);
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return banners.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Swiper), {
        style: _normalizeStyle(_unref(CONFIG).banners),
        onSwiper: setBannersSlider,
        class: "banners-slider",
        modules: [_unref(Navigation), _unref(Pagination), _unref(Autoplay)],
        navigation: true,
        pagination: {
          clickable: true
        },
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        loop: !_unref(isOneSlide),
        enabled: !_unref(isOneSlide)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banners.value, item => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: item.id,
            class: "banners-slider__slide"
          }, {
            default: _withCtx(() => [item.link ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [item.link.substr(0, 4) === 'http' ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "banners-slider__link",
              href: item.link
            }, [_createElementVNode("img", {
              class: "banners-slider__image",
              src: item.img
            }, null, 8, _hoisted_3)], 8, _hoisted_2)) : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: item.link.startsWith('/') ? item.link : '/' + item.link
            }, {
              default: _withCtx(() => [_createElementVNode("img", {
                class: "banners-slider__image",
                src: item.img
              }, null, 8, _hoisted_4)]),
              _: 2
            }, 1032, ["to"]))], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
              class: "banners-slider__image",
              src: item.img
            }, null, 8, _hoisted_6)]))]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["style", "modules", "loop", "enabled"])])) : _createCommentVNode("", true);
    };
  }

};