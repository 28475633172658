import { watch, ref } from "vue";

const STORAGE_KEY = 'b2b.item.itemOfferStorageMap'

const map = ref(
    JSON.parse(
        localStorage.getItem(STORAGE_KEY) ?? '{}'
    )
)

watch([map], ([m]) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(m))
})

export const useItemOfferStorage = () => {
  return {
    clear: () => map.value = {},
    update: (itemId, itemOfferId) => {
      map.value = {
          ...map.value,
          [itemId]: itemOfferId,
      }
    },
    map,
  }
}
