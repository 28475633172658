import PageLogin from "@/features/auth/PageLogin.vue";
import PageRegister from "@/features/auth/register/PageRegister.vue";
import PageRestore from "@/features/auth/PageRestore.vue";
import PageNewPassword from "@/features/auth/PageNewPassword.vue";

export const auth = [
    {path: '/auth', name: 'Authorization', redirect: '/login',},
    {
        path: '/login',
        name: 'auth.login',
        component: PageLogin,
        meta: {
            authNotRequired: true,
            layout: 'LayoutAuth',
            navTitle: 'Вход',
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        component: PageRegister,
        meta: {
            authNotRequired: true,
            layout: 'LayoutAuth',
            navTitle: 'Регистрация',
        },
    },
    {
        path: '/restore',
        name: 'auth.restore',
        component: PageRestore,
        meta: {
            authNotRequired: true,
            layout: 'LayoutAuth',
            navTitle: 'Восстановление пароля',
        },
    },
    {
        path: '/newPassword',
        name: 'auth.newPassword',
        component: PageNewPassword,
        meta: {
            authNotRequired: true,
            layout: 'LayoutAuth',
            navTitle: 'Восстановление пароля',
        }
    },
]
