import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-75aad986"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "card-header"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "actions"
};
const _hoisted_4 = {
  class: "card-sub-header"
};
export default {
  __name: 'AppCard',
  props: {
    'noTitle': Boolean
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      return _openBlock(), _createBlock(_component_el_card, null, _createSlots({
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 2
      }, [props.noTitle !== true ? {
        name: "header",
        fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "actions")])]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "subtitle")])])
      } : undefined]), 1024);
    };
  }

};