import { ref, watch } from "vue"
import { useBasket } from "@/features/baskets/useBasket.js";

const basketModule = useBasket()

const buffer = ref({})

watch([basketModule.bundlesOfCurrentBasket], async ([bundlesOfCurrentBasket]) => {
  for (const b of bundlesOfCurrentBasket) {
    buffer.value[b.itemOfferId] = b.amount
  }
}, {immediate: true})


const bundleByItemOfferId = (itemOfferId) => {
  return basketModule.bundlesOfCurrentBasket.value?.find((b) =>
      String(b.itemOfferId) === String(itemOfferId)
  ) ?? null
}

const set = async (itemOfferId) => {
  const bundle = bundleByItemOfferId(itemOfferId) ?? null
  const amountBuffer = buffer.value?.[itemOfferId] ?? 0
  const amountBundle = bundle?.amount ?? 0

  // Если такого бандла нет, то создаем
  if (amountBundle === 0) {
    await basketModule.addItemToCurrentBasket(itemOfferId, amountBuffer)

  // Если бандл есть, то обновляем
  } else {
    if (amountBuffer === 0) {
      await basketModule.removeBundle(bundle.id)
    } else {
      await basketModule.updateBundle(bundle.id, amountBuffer)
    }
  }
}

const bufferHasChanges = (itemOfferId) => {
  return buffer.value[itemOfferId] !== (bundleByItemOfferId(itemOfferId)?.amount ?? 0)
}

export const useItemOfferAmounts = () => {
  return {
    bundleByItemOfferId,
    set,
    bufferHasChanges,
    buffer,
  }
}
