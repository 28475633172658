import PageItem from "@/features/item/PageItem.vue";

export const item = [
    {
        path: '/item/:id',
        name: 'item.detailed',
        component: PageItem,
        meta: {
            navTitle: 'Загрузка...',
        }
    }
]
