import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4c83ade7"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "documents-list"
};
const _hoisted_2 = {
  key: 0,
  class: "filters"
};
import DocumentsItem from "@/features/documents/representation/DocumentsItem.vue";
import { onMounted, ref, reactive, watch } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
export default {
  __name: 'DocumentsList',

  setup(__props) {
    onMounted(() => {
      loadDocuments();
    });
    const entityDocument = useEntity('Document');
    const documents = ref([]);
    const documentsFilters = ref([]);

    const loadDocuments = async () => {
      const res = await entityDocument.list();
      documents.value = res.result;
      documentsFilters.value = res.filters;
    };

    const filters = reactive({
      category: '',
      dateFrom: ''
    }); // Переменная, чтобы element-plus корректно отоборажал дату юзер,
    // если менять напрямую дату для сервера, то element-plus некорректно отобразит дату

    const date = ref(null);

    const formatDate = () => {
      if (date.value) {
        // Тут убирается лишние 3 нуля из конца, чтобы сервер корректно обработал дату
        filters.dateFrom = Number(date.value.toString().substring(0, 10));
      } else {
        filters.dateFrom = '';
      }
    };

    watch(filters, async newValue => {
      const filter = newValue;
      const res = await entityDocument.list({
        filter
      });
      documents.value = res.result;
    });
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_empty = _resolveComponent("el-empty");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [documentsFilters.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documentsFilters.value, category => {
        return _openBlock(), _createElementBlock("div", {
          key: category.key,
          class: "filters-item"
        }, [category.type === 'select' ? (_openBlock(), _createBlock(_component_el_select, {
          key: 0,
          modelValue: filters.category,
          "onUpdate:modelValue": $event => filters.category = $event,
          placeholder: category.title,
          size: "large",
          clearable: true
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(category.values_names), key => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: key,
              label: category.values_names[key],
              value: key
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])) : _createCommentVNode("", true), category.type === 'date' ? (_openBlock(), _createBlock(_component_el_date_picker, {
          key: 1,
          modelValue: date.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => date.value = $event),
          placeholder: category.title,
          size: "large",
          format: "YYYY/MM/DD",
          "value-format": "x",
          onChange: formatDate
        }, null, 8, ["modelValue", "placeholder"])) : _createCommentVNode("", true)]);
      }), 128))])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documents.value, doc => {
        return _withDirectives((_openBlock(), _createBlock(DocumentsItem, {
          key: doc.id,
          document: doc,
          onDeleted: loadDocuments
        }, null, 8, ["document"])), [[_directive_loading, _unref(entityDocument).loading.value]]);
      }), 128))]), documents.value?.length === 0 && !_unref(entityDocument).loading.value ? (_openBlock(), _createBlock(_component_el_empty, {
        key: 0,
        description: "Список документов пуст"
      })) : _createCommentVNode("", true)], 64);
    };
  }

};