import PageUsers from '@/features/users/representation/PageUsers.vue';
import PageUsersEdit from '@/features/users/edit/PageUsersEdit.vue';

export const manageUsers = [
    {
        path: '/users',
        name: 'users.representation',
        component: PageUsers,
        meta: {
            navTitle: 'Пользователи',
        }
    },
    {
        path: '/users/:id',
        name: 'users.detailed',
        component: PageUsersEdit,
        meta: {
            navTitle: 'Редактирование пользователя ',
        }
    }
]
