import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import ItemsTileShow from "@/features/catalog/itemsRepresentation/ItemsTileShow.vue";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useEntity } from "@/features/api/useEntity.js";
export default {
  __name: 'PageItemSet',

  setup(__props) {
    const entityItemSet = useEntity('ItemSet');
    const route = useRoute();
    const itemSet = ref(null);
    watch([route], async ([route]) => {
      if (!route?.params?.id) return;
      const res = await entityItemSet.get(route.params.id);
      itemSet.value = res.result;
      useNavWatcher().title.value = itemSet?.value?.title ?? 'Загрузка...';
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_createVNode(PageHeader), itemSet.value?.items ? (_openBlock(), _createBlock(ItemsTileShow, {
          key: 0,
          items: itemSet.value.items
        }, null, 8, ["items"])) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }

};