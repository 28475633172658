import { Buffer } from 'buffer';
import { ref } from 'vue';
import { useNotification } from '@/features/notifications/useNotification.js';
import { useBackend } from "@/features/api/useBackend.js";
import { useEntity } from "@/features/api/useEntity.js";
import { useSplashScreen } from "@/features/splashScreen/useSplashScreen.js";

useSplashScreen().register('auth')

const notification = useNotification();

const backend = useBackend({
  showNotificationOnError: false,
})

const user = ref(null);
const loading = ref(true);


// Инициализация при первом использовании
Promise.resolve().then(async () => {
  try {
    loading.value = true
    const res = (await backend.api('profile')).data.result
    user.value = (await useEntity('User').get(res.ID)).result
  } finally {
    loading.value = false
    useSplashScreen().done('auth')
  }
})


export const useAuth = () => {

  const login = async (username, password) => {
    try {
      loading.value = true;
      const base64 = new Buffer(`${username}:${password}`).toString('base64');
      const res = (await backend.api(
          'profile',
          null,
          { headers: { Authorization: `Basic ${base64}`}}
      )).data.result

      user.value = (await useEntity('User').get(res.ID)).result

    } catch {
      notification.show({
        type: 'error',
        title: 'Ошибка авторизации!',
        message: 'Неверный логин или пароль',
      })

    } finally {
      loading.value = false
    }
  };

  const logout = () => {
    loading.value = true;
    return backend.api('/bitrix/admin/', null, { params: { logout: 'yes' }, baseURL: '/', method: 'GET' })
    .then(() => {
      user.value = null
      backend.sessid.value = ''
    })
    .finally(() => loading.value = false);
  }

  return {
    user,
    loading,
    login,
    logout,
  }
};

