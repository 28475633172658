import PageMailings from "@/features/mailings/PageMailings.vue";

export const mailings = [
  {
    path: '/mailings',
    name: 'mailings.edit',
    component: PageMailings,
    meta: {
      navTitle: 'Подписка на рассылку',
    }
  }
]
