import PageCatalogCategories from "@/features/catalog/categoriesRepresentation/PageCatalogCategories.vue";
import PageCatalogItems from "@/features/catalog/itemsRepresentation/PageCatalogItems.vue";

export const catalog = [
    {
        path: '/catalog',
        name: 'catalog',
        component: PageCatalogCategories,
        meta: {
            navTitle: 'Каталог',
        },
    },
    {
        path: '/items/:categoryId',
        name: 'catalog.items',
        component: PageCatalogItems,
        meta: {
            navTitle: 'Каталог',
        },
    },
]
