import { computed, ref, watch } from "vue";

const keysForRemove = {
  vars: {
    '--el-color-primary': 'ignore',
    '--el-color-primary-light-3': 'ignore',
    '--el-color-primary-light-5': 'ignore',
    '--el-color-primary-light-7': 'ignore',
    '--el-color-primary-light-8': 'ignore',
    '--el-color-primary-light-9': 'ignore',
    '--el-color-primary-dark-2': 'ignore',
  }
}

const themes = [
  {
    key: 'orange',
    label: 'Оранжевая',
    vars: {
      '--el-color-primary': '#ff5f00',
      '--el-color-primary-light-3': '#ff8f4d',
      '--el-color-primary-light-5': '#ffaf80',
      '--el-color-primary-light-7': '#ffcfb3',
      '--el-color-primary-light-8': '#ffdfcc',
      '--el-color-primary-light-9': '#ffefe6',
      '--el-color-primary-dark-2': '#cc4c00',
    }
  },
  {
    key: 'blue',
    label: 'Голубая',
    vars: {
      '--el-color-primary': '#3185FC',
      '--el-color-primary-light-3': '#6faafd',
      '--el-color-primary-light-5': '#98c2fe',
      '--el-color-primary-light-7': '#c1dafe',
      '--el-color-primary-light-8': '#d6e7fe',
      '--el-color-primary-light-9': '#eaf3ff',
      '--el-color-primary-dark-2': '#276aca',
    }
  },
  {
    key: 'green-dark',
    label: 'Тёмно зелёная',
    vars: {
      '--el-color-primary': '#156064',
      '--el-color-primary-light-3': '#5b9093',
      '--el-color-primary-light-5': '#8ab0b2',
      '--el-color-primary-light-7': '#b9cfd1',
      '--el-color-primary-light-8': '#d0dfe0',
      '--el-color-primary-light-9': '#e8eff0',
      '--el-color-primary-dark-2': '#114d50',
    }
  },
  {
    key: 'oxford-blue',
    label: 'Фиолетовая',
    vars: {
      '--el-color-primary': '#2f1652',
      '--el-color-primary-light-3': '#8773d9',
      '--el-color-primary-light-5': '#8c63e0',
      '--el-color-primary-light-7': '#9d86f3',
      '--el-color-primary-light-8': '#9554bb',
      '--el-color-primary-light-9': '#9900ff',
      '--el-color-primary-dark-2': '#332574',
    }
  },
  {
    key: 'ruby-red',
    label: 'Рубиновая',
    vars: {
      '--el-color-primary': '#9A031E',
      '--el-color-primary-light-3': '#b84f62',
      '--el-color-primary-light-5': '#cd818f',
      '--el-color-primary-light-7': '#e1b3bc',
      '--el-color-primary-light-8': '#ebcdd2',
      '--el-color-primary-light-9': '#f5e6e9',
      '--el-color-primary-dark-2': '#7b0218',
    }
  },
  {
    key: 'red',
    label: 'Красная',
    vars: {
      '--el-color-primary': '#DB0304',
      '--el-color-primary-light-3': '#e64f4f',
      '--el-color-primary-light-5': '#ed8182',
      '--el-color-primary-light-7': '#f4b3b4',
      '--el-color-primary-light-8': '#f8cdcd',
      '--el-color-primary-light-9': '#fbe6e6',
      '--el-color-primary-dark-2': '#af0203',
    }
  },
]

const activeThemeKey = ref(null)
const theme = computed(() => themes.find(t => t.key === activeThemeKey.value))
watch(activeThemeKey, (themeKey) => {
  for (const varName of Object.keys(theme.value?.vars ?? keysForRemove.vars)) {
    if (themeKey === null) {
      document.documentElement.style.removeProperty(varName)
    } else {
      document.documentElement.style.setProperty(varName, theme.value.vars[varName])
    }
  }
})

export const useThemes = () => {
  return {
    themes,
    activeThemeKey,
    theme,
  }
}
