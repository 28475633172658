let jobs = []

export const useSplashScreen = () => {
  return {
    jobs,

    register: (jobName) => {
      jobs.push(jobName)
    },

    done: (jobName) => {
      jobs = jobs.filter(j => j !== jobName)

      if (jobs.length === 0) {
        document.getElementById('splashScreen').classList.add('splashScreen__hide')
      }
    }
  }
}
