import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "order-details-ask"
};
import OrderDetailsAskLegal from "@/features/orders/creating/OrderDetailsAskLegal.vue";
import OrderDetailsAskShipping from "@/features/orders/creating/OrderDetailsAskShipping.vue";
import OrderDetailsAskPayment from "@/features/orders/creating/OrderDetailsAskPayment.vue";
import { ref, watch } from "vue";
export default {
  __name: 'OrderDetailsAsk',
  emits: ['details'],

  setup(__props, {
    emit
  }) {
    const legal = ref(null);

    const onLegal = l => legal.value = l;

    const shipping = ref(null);

    const onShipping = s => shipping.value = s;

    const customAddress = ref(null);

    const onCustomAddress = ca => customAddress.value = ca;

    const payment = ref(null);

    const onPayment = p => payment.value = p;

    watch([payment, shipping, customAddress, legal], ([payment, shipping, customAddress, legal]) => {
      emit('details', {
        payment,
        shipping,
        customAddress,
        legal
      });
    });
    return (_ctx, _cache) => {
      const _component_el_collapse = _resolveComponent("el-collapse");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_collapse, null, {
        default: _withCtx(() => [_createVNode(OrderDetailsAskLegal, {
          onLegal: onLegal
        }), _createVNode(OrderDetailsAskShipping, {
          onShipping: onShipping,
          onCustomAddress: onCustomAddress
        }), _createVNode(OrderDetailsAskPayment, {
          onPayment: onPayment
        })]),
        _: 1
      })]);
    };
  }

};