import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7adfa2b5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "page-header"
};
const _hoisted_2 = ["textContent"];
import { computed } from "vue";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
export default {
  __name: 'PageHeader',
  props: {
    'title': String,
    'breadcrumb': Array,
    'loading': Boolean
  },

  setup(__props) {
    const props = __props;
    const navWatcher = useNavWatcher();
    const title = computed(() => {
      return props.title || navWatcher.title.value || null;
    });
    const breadcrumb = computed(() => {
      return props.breadcrumb || [];
    });
    return (_ctx, _cache) => {
      const _component_el_skeleton = _resolveComponent("el-skeleton");

      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [props.loading ? (_openBlock(), _createBlock(_component_el_skeleton, {
        key: 0,
        rows: 1,
        animated: ""
      })) : _unref(breadcrumb).length > 0 ? (_openBlock(), _createBlock(_component_el_breadcrumb, {
        key: 1,
        separator: "/",
        class: "breadcrumb"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(breadcrumb), b => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            to: b.to,
            key: b.text
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(b.text), 1)]),
            _: 2
          }, 1032, ["to"]);
        }), 128)), _createVNode(_component_el_breadcrumb_item)]),
        _: 1
      })) : _createCommentVNode("", true), props.loading ? (_openBlock(), _createBlock(_component_el_skeleton, {
        key: 2,
        rows: 1,
        animated: ""
      })) : _unref(title) ? (_openBlock(), _createElementBlock("h1", {
        key: 3,
        class: "header",
        textContent: _toDisplayString(_unref(title))
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true)]);
    };
  }

};