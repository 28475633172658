import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2171a878"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "items-tile-show"
};
const _hoisted_2 = {
  class: "items"
};
import ItemCard from "@/features/catalog/itemCard/ItemCard.vue";
export default {
  __name: 'ItemsTileShow',
  props: {
    items: Array
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items, i => {
        return _openBlock(), _createBlock(ItemCard, {
          key: i,
          class: "item",
          item: i
        }, null, 8, ["item"]);
      }), 128))])]);
    };
  }

};