import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-71c15f92"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "label"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "label"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "label"
};
import { computed } from "vue";
import { Icon } from "@iconify/vue";
import { unlockBody } from "@/features/utils/utils";
export default {
  __name: 'BottomMenuMoreElement',
  props: {
    'index': Number,
    'title': String,
    'icon': String,
    'link': Object,
    'hiddenOnMobile': Boolean,
    'children': Array
  },

  setup(__props) {
    const props = __props;
    const hasChildren = computed(() => {
      if (!props.children) return false;
      return props.children?.length > 0;
    });

    const hideMoreMenu = evt => {
      evt.target.closest('.more').classList.remove('show');
      unlockBody();
    };

    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");

      const _component_el_sub_menu = _resolveComponent("el-sub-menu");

      const _component_el_menu_item = _resolveComponent("el-menu-item");

      return props.hiddenOnMobile && _unref(hasChildren) ? (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 0,
        index: props.index
      }, {
        title: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_unref(Icon), {
          icon: props.icon,
          class: "icon"
        }, null, 8, ["icon"]), _createElementVNode("div", _hoisted_2, _toDisplayString(props.title), 1)])]),
        default: _withCtx(() => [_createVNode(_component_el_menu_item_group, {
          class: "submenu"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.children, child => {
            return _openBlock(), _createBlock(_component_router_link, {
              onClick: hideMoreMenu,
              to: child.link,
              class: "child",
              key: child.title
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_unref(Icon), {
                icon: child.icon,
                class: "icon"
              }, null, 8, ["icon"]), _createElementVNode("div", _hoisted_4, _toDisplayString(child.title), 1)])]),
              _: 2
            }, 1032, ["to"]);
          }), 128))]),
          _: 1
        })]),
        _: 1
      }, 8, ["index"])) : props.hiddenOnMobile && !_unref(hasChildren) ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 1,
        index: props.index
      }, {
        default: _withCtx(() => [_createVNode(_component_router_link, {
          onClick: hideMoreMenu,
          to: props.link,
          class: "link"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_unref(Icon), {
            icon: props.icon,
            class: "icon"
          }, null, 8, ["icon"]), _createElementVNode("div", _hoisted_6, _toDisplayString(props.title), 1)])]),
          _: 1
        }, 8, ["to"])]),
        _: 1
      }, 8, ["index"])) : _createCommentVNode("", true);
    };
  }

};