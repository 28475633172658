import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ac6decec"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
import AppHeader from "@/features/layoutStandart/AppHeader.vue";
import AppFooter from "@/features/layoutStandart/AppFooter.vue";
import AsideMenu from "@/features/layoutStandart/AsideMenu.vue";
import BottomMenu from "@/features/layoutStandart/BottomMenu";
import { viewports } from "@/features/utils/viewports";
export default {
  __name: 'LayoutDefault',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_component_el_container, {
        style: {
          "min-height": "100vh"
        },
        direction: "vertical"
      }, {
        default: _withCtx(() => [_createVNode(AppHeader), _createElementVNode("div", _hoisted_1, [_unref(viewports).sm.value ? (_openBlock(), _createBlock(AsideMenu, {
          key: 0
        })) : _createCommentVNode("", true), !_unref(viewports).sm.value ? (_openBlock(), _createBlock(_unref(BottomMenu), {
          key: 1
        })) : _createCommentVNode("", true), _createVNode(_component_router_view)]), _createVNode(AppFooter)]),
        _: 1
      });
    };
  }

};