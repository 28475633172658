import PageNews from "@/features/news/PageNews.vue"
import PageNewsDetailed from "@/features/news/PageNewsDetailed.vue"

export const news = [
    {
        path: '/news',
        name: 'news.representation',
        component: PageNews,
        meta: {
            navTitle: 'Новости',
        },
    },
    {
        path: '/news/:id',
        name: 'news.detailed',
        component: PageNewsDetailed,
        meta: {
            navTitle: 'Загрузка...',
        },
    },
]
