import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0b58bce4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "pagination"
};
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import ItemsListShow from "@/features/catalog/itemsRepresentation/ItemsListShow.vue";
import { ref, onMounted, reactive, watch } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { getBalanceForProducts } from "@/features/utils/utils";
export default {
  __name: 'PageFavorites',

  setup(__props) {
    onMounted(() => {
      loadFavorites();
    }); // Пагинация

    const pagination = reactive({
      pageSizes: [10, 25, 50, 100, 200],
      total: 0
    });
    const curPage = ref(1);
    const curSize = ref(pagination.pageSizes[1]);
    watch([curPage, curSize], () => {
      loadFavorites();
    }); // Загрузка избранного

    const favorites = ref([]);
    const entityFavorite = useEntity('Favorite');

    const loadFavorites = async () => {
      const res = await entityFavorite.list({
        page: curPage.value,
        size: curSize.value
      });
      favorites.value = res.result;
      getBalanceForProducts(favorites.value);
      pagination.total = Number(res.count);
    };

    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, {
        class: "page-favorites"
      }, {
        default: _withCtx(() => [_createVNode(PageHeader), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
          currentPage: curPage.value,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => curPage.value = $event),
          "page-size": curSize.value,
          "onUpdate:page-size": _cache[1] || (_cache[1] = $event => curSize.value = $event),
          layout: "sizes, total, prev, pager, next",
          total: pagination.total,
          "page-sizes": pagination.pageSizes
        }, null, 8, ["currentPage", "page-size", "total", "page-sizes"])]), _createVNode(ItemsListShow, {
          items: favorites.value,
          onRequireUpdate: loadFavorites
        }, null, 8, ["items"])])), [[_directive_loading, _unref(entityFavorite).loading.value]])]),
        _: 1
      });
    };
  }

};