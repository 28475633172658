import PageOrderCreation from "@/features/orders/creating/PageOrderCreation.vue";
import PageOrders from "@/features/orders/representation/PageOrders.vue";
import PageOrderDetailed from "@/features/orders/detailed/PageOrderDetailed.vue";
import PageOrderDetailed_Print from "@/features/orders/detailed/PageOrderDetailed_Print.vue";
import PageOrderCSV from "@/features/orders/creating/PageOrderCSV";

export const orders = [
  {
    path: '/orders',
    name: 'orders.representation',
    component: PageOrders,
    meta: {
      navTitle: 'Заказы',
    },
  },
  {
    path: '/orders/create',
    name: 'orders.create',
    component: PageOrderCreation,
    meta: {
      navTitle: 'Оформление заказа №',
    },
  },
  {
    path: '/orders/:id',
    name: 'orders.detailed',
    component: PageOrderDetailed,
    meta: {
      navTitle: 'Заказ №',
    },
  },
  {
    path: '/orders/:id/_print',
    name: 'orders.detailed._print',
    component: PageOrderDetailed_Print,
    meta: {
      layout: 'LayoutPrint',
      navTitle: 'Печать заказа №',
    },
  },
  {
    path: '/orders/csv',
    name: 'orders.csv',
    component: PageOrderCSV,
    meta: {
      navTitle: 'Создать заказ через CSV',
    },
  }
]
