import PageActs from "@/features/acts/PageActs";

export const acts = [
    {
        path: '/acts',
        name: 'acts.representation',
        component: PageActs,
        meta: {
            navTitle: 'Акты сверки',
        },
    },
    {
        path: '/acts/:id',
        name: 'acts.detailed',
        component: PageActs,
        meta: {
            navTitle: 'Акты сверки',
        },
    },
]
