import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-645d1bf3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "square"
};
const _hoisted_2 = {
  class: "icon-wrapper"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "square"
};
const _hoisted_5 = {
  class: "icon-wrapper"
};
const _hoisted_6 = {
  class: "title"
};
import { Icon } from "@iconify/vue";
import { lockBody } from "@/features/utils/utils";
export default {
  __name: 'BottomMenuElement',
  props: {
    'title': String,
    'icon': String,
    'link': Object,
    'children': Array,
    'hiddenOnMobile': Boolean
  },

  setup(__props) {
    const props = __props;

    const showMoreMenu = evt => {
      const target = evt.target.closest('.elements').querySelector('.more');
      target.classList.add('show');
      lockBody(target, true);
    };

    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createElementBlock(_Fragment, null, [props.title !== 'Еще' && !props.hiddenOnMobile ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: props.link,
        class: "element"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(Icon), {
          icon: props.icon,
          class: "icon"
        }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_3, _toDisplayString(props.title), 1)])]),
        _: 1
      }, 8, ["to"])) : _createCommentVNode("", true), props.title === 'Еще' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "element",
        onClick: showMoreMenu
      }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_unref(Icon), {
        icon: props.icon,
        class: "icon"
      }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_6, _toDisplayString(props.title), 1)])])) : _createCommentVNode("", true)], 64);
    };
  }

};