import { computed } from "vue";
import { usePermissions } from "@/features/permissions/usePermissions.js";
import { viewports } from "@/features/utils/viewports";

const baseElements = computed(() => {
  return [
    {
      title: 'Каталог',
      icon: 'ic:outline-shopping-cart',
      link: {name: 'catalog'},
      hasPopover: viewports.lg.value,
    },
    {
      title: 'Рабочий стол',
      icon: 'ic:outline-space-dashboard',
      link: {name: 'dashboard'},
    },
    {
      title: 'Заказы',
      icon: 'bx:basket',
      link: {name: 'orders.representation'},
      children: [
        {
          title: 'Журнал заказов',
          icon: 'bx:book',
          link: {name: 'orders.representation'},
        },
        {
          title: 'Создать заказ через CSV',
          icon: 'bx:spreadsheet',
          link: {name: 'orders.csv'},
        },
      ],
    },
    {
      title: 'Документы',
      icon: 'bx:folder-open',
      link: {name: 'documents.representation'},
      hiddenOnMobile: true,
      children: [
        {
          title: 'Пользовательские файлы',
          icon: 'bx:file-blank',
          link: {name: 'documents.representation'},
        },
      ],
    },
    {
      title: 'Управление',
      icon: 'bx:message-square-edit',
      link: '#',
      hiddenOnMobile: true,
      children: [
        {
          title: 'Юр. лица',
          icon: 'bx:briefcase',
          link: {name: 'legals.representation'},
        },
        {
          title: 'Мой профиль',
          icon: 'bx:user',
          link: {name: 'users.detailed', params: {id: 'me'}},
        },
        {
          title: 'Пользователи',
          icon: 'bx:group',
          link: {name: 'users.representation'},
        },
        {
          title: 'Рассылка',
          icon: 'bx:envelope',
          link: {name: 'mailings.edit'},
        },
      ],
    },
  ]
});

const permissions = usePermissions();
const retailElements = computed(() => {
  const retail = {
    title: 'Ретейл',
    icon: 'bx:store',
    link: '#',
    hiddenOnMobile: true,
    children: [
      {
        title: 'Брендирование',
        icon: 'bx:brush',
        link: {name: 'retail.branding'},
      },
      {
        title: 'Отключить Клиент рядом',
        icon: 'bx:exit',
        link: {name: 'retail.disable'},
      }
    ],
  }


  if (permissions.has('margins')) {
    retail.children[2] = retail.children[1]
    retail.children[1] = retail.children[0]
    retail.children[0] = {
      title: 'Ценообразование',
      icon: 'bx:money',
      link: {name: 'retail.margins.representation'},
    }
  }

  return [retail]
})

// Пункт "Ещё" на мобилках
const moreElement = [
  {
    title: 'Еще',
    icon: 'bx:dots-horizontal-rounded',
    link: '#'
  }
];

// Пункт меню "Создать заказ через CSV" в мобилках
if (!viewports.sm.value) {
  baseElements.value.push(
      {
        title: 'Заказы',
        icon: 'bx:basket',
        link: '#',
        hiddenOnMobile: true,
        children: [
          {
            title: 'Создать заказ через CSV',
            icon: 'bx:spreadsheet',
            link: {name: 'orders.csv'},
          }
        ],
      }
  )
}

const elements = computed( () => {
  const res = []
  res.push(...baseElements.value)
  if (permissions.has('retail')) {
    res.push(...retailElements.value)
  }

  if (!viewports.sm.value) {
    res.push(...moreElement)
  }

  return res
})

export const useNavMenu = () => {
  return {
    elements,
  }
}
