import { watch, ref } from "vue";
import { CONFIG } from "@/config.js";

const title = ref('')

watch([title], ([title]) => {
  document.title = title + CONFIG.navWatcher.titleSuffix
}, {immediate: true})


export const useNavWatcher = (route) => {
  watch([route], ([r]) => {
    const t = r?.meta?.navTitle ?? r?.meta?.value?.title ?? null
    if (t) title.value = t
  }, {immediate: true})


  return {
    title,
  }
}
