import PageDocuments from "@/features/documents/representation/PageDocuments.vue";
import PageDocumentUpload from "@/features/documents/creating/PageDocumentUpload.vue";

export const documents = [
    {
        path: '/documents',
        name: 'documents.representation',
        component: PageDocuments,
        meta: {
            navTitle: 'Документы',
        },
    },
    {
        path: '/documents/upload',
        name: 'documents.create',
        component: PageDocumentUpload,
        meta: {
            navTitle: 'Загрузка документа',
        },
        props: true,
    },
]
