import { computed, ref, watch } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { useItemOfferStorage } from "@/features/item/useItemOfferStorage.js";
import { useAuth } from "@/features/auth/useAuth.js";
import { join } from "lodash-es";

const selectedItemOfferId = ref(null)
const entityCatalogCategory = useEntity('CatalogCategory')
const categories = ref([])

const auth = useAuth()
watch([auth.user], () => {
  if (!auth.user.value) return
  entityCatalogCategory.list().then((res) => {
    categories.value = res.result
  })
}, {immediate: true})


const itemB = ref(null)

const item = computed(() => {
  return itemB.value?.entity ?? null
})

watch(item, (item) => {
  if (!item) return
  if (item?.offers?.length === 1) selectedItemOfferId.value = item?.offers?.[0]?.id ?? null
})

const selectedItemOffer = computed(() => {
  if (!item.value) return null
  if (!selectedItemOfferId.value) return null
  return item.value?.offers?.find(o => o.id === selectedItemOfferId.value) ?? null
})


const itemOfferStorage = useItemOfferStorage()
watch(selectedItemOfferId, (id) => {
  itemOfferStorage.update(item.value.id, id)
})

const mapFilter = (c) => {
  if (c.value === '') return {label: null, value: null}
  if (c.value === false) return {label: null, value: null}
  if (typeof c.value === 'string') return {label: c.key, value: c.value}
  return {
    label: c.key,
    value: join(c.value, ', ')
  }
}

const characteristics = computed(() => {
  return (item.value?.characteristics
          ?.map(mapFilter) ?? [])
          ?.filter((item) => item.value !== null)
      ?? []
})

const itemOfferCharacteristics = computed(() => {
  return (selectedItemOffer?.value?.characteristics
          ?.map(mapFilter) ?? [])
          ?.filter((item) => item.value !== null)
      ?? []
})

const itemOfferWarehouses = computed(() => {
  return selectedItemOffer?.value?.warehouses ?? [];
})

export const statePageItem = (itemBind = null) => {
  if (itemBind) itemB.value = itemBind
  let firstLoad = true

  watch(item, (item) => {
    if (item && firstLoad) {
      firstLoad = false

      if (itemOfferStorage.map?.value?.[item?.id]) {
        selectedItemOfferId.value = itemOfferStorage.map?.value?.[item?.id]
      }
    }
  })

  return {
    item,
    itemBind,
    itemB,
    categories,
    selectedItemOfferId,
    selectedItemOffer,
    characteristics,
    itemOfferCharacteristics,
    itemOfferWarehouses,
  }
}
