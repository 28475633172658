import { useBasket } from "@/features/baskets/useBasket";
import { computed, ref, unref } from "vue";
import { useEntity } from "@/features/api/useEntity";
import { usePriceBin } from "@/features/prices/usePriceBin";
import { getBalanceForProducts, moneyFormat, normalizePhotoUrl } from "@/features/utils/utils";
import { useBundleAmounts}  from "@/features/baskets/useBundleAmounts";
import { get } from "lodash-es";

const basket = useBasket();

const possibleToChangeAmounts = (props) => {
    return !props.disableChangeAmount && props.basketId === basket.curBasketId.value
}

const removeBundle = async (bundle) => {
    await basket.removeBundle(bundle.id)
}

const selectedItemOffer = (bundle) => {
    return bundle.item.offers.find((io) => {
        return "" + io.id === bundle.itemOfferId
    }) ?? null
}

const bundleEntity = useEntity('Bundle');
const bundles = ref([]);
const updateBundles = async (props) => {
    const filter = {}
    if (props?.basketId) filter.basketId = unref(props?.basketId)
    else if (props?.orderId) filter.orderId = unref(props?.orderId)
    const res = await bundleEntity.list({filter})
    bundles.value = res.result
}

const priceBin = usePriceBin()
const tableData = computed(() => {
    if (bundles.value.length === 0) return []
    return bundles.value.map((b) => {
        const itemOffer = selectedItemOffer(b)
        const price = priceBin.get(itemOffer?.price)

        // Тут обернул bundle в массив чтобы map() в функции getBalanceForProducts корректно отработал
        getBalanceForProducts([b.item])

        return {
            id: b?.id,
            itemId: b?.item?.id,
            a: b?.item?.a,
            title: b?.item?.title,
            offerTitle: get(itemOffer, 'title', '(неизвестно)'),
            price: moneyFormat(price),
            priceRaw: price,
            amount: b?.amount,
            availability: {
                quantity: get(itemOffer, 'balance', ''),
                unit: get(itemOffer, 'unit', ''),
            },
            photo: normalizePhotoUrl(b?.item?.photoMain),
            total: moneyFormat(price, {valueMultiplier: b?.amount}),
        }
    })
})

// Изменение количества товаров
const bam = useBundleAmounts()

const bufferAmounts = bam.bufferAmounts;

const showUpdateButton = (bundle) => {
    return bam.bufferHasChanges(bundle.id)
}

const canChangeAmount = (bundle) => {
    for (const b of bundles.value) {
        if (b.id === bundle.id) continue
        if (showUpdateButton(b)) return false
    }
    return true
}

const link = (scopeRow) => {
    return {
        name: 'item.detailed',
        params: {
            id: scopeRow.itemId
        }
    }
}

const confirmBuffer = async (scopeRow) => {
    await bam.confirmBuffer(scopeRow.id)
}

const resetBuffer = (scopeRow) => {
    bam.resetBuffer(scopeRow.id)
}

const loading = computed(() => basket.loading.value || bundleEntity.loading.value)

export const useBasketItems = () => {
    return {
        tableData,
        loading,

        possibleToChangeAmounts,
        removeBundle,
        selectedItemOffer,
        updateBundles,

        bufferAmounts,
        showUpdateButton,
        canChangeAmount,
        link,
        confirmBuffer,
        resetBuffer,
    }
}
