import { moneyFormat } from "@/features/utils/utils";
import { usePriceBin } from "@/features/prices/usePriceBin";
import { ref } from "vue";

const tableData = (orders) => {
    if (!orders) return []

    const userToInitials = (user) => {
        let userTitle = user.lastName
        if (user?.firstName?.[0]) userTitle += " " + user.firstName[0] + ". "
        if (user?.middleName?.[0]) userTitle += user.middleName[0] + "."
        return userTitle
    }

    return orders.map(order => {
        let userTitle = userToInitials(order.user)

        const legalTitle = order?.companyLegal?.officialShort ?? order?.companyLegal?.fields?.[0]?.value ?? '[неизвестно]'

        let tooltipContent = null
        if (order?.cancelInfo) {
            const date = (new Date(order.cancelInfo.date * 1000)).toLocaleDateString('ru-RU')
            const who = userToInitials(order.cancelInfo.user)
            const reason = order.cancelInfo.reason
            tooltipContent = `Дата: ${date}<br/>Кем: ${who}<br/>Причина: ${reason}`
        }

        let onlinePay = order?.onlinePay ?? null
        if (onlinePay === null || onlinePay?.status === null) {
            onlinePay = {
                status: 'disabled',
                comment: null,
                link: null,
            }
        }

        return {
            ...order,
            displayPrice: moneyFormat(usePriceBin().get(order?.price)),
            displayDate: new Date(order.date * 1000).toLocaleDateString('ru-RU'),
            styleStatus: {
                backgroundColor: order.status.color,
            },
            displayLegal: legalTitle,
            displayUser: userTitle,
            tooltipContent: tooltipContent,
            onlinePay,
        }
    })
}

const onlinePay = ref({
    comment: '',
    link: '',
})

const onBtnPayClick = (order, refDialogOrderOnlinePay) => {
    onlinePay.value.comment = order?.onlinePay?.comment
    onlinePay.value.link = order?.onlinePay?.link
    refDialogOrderOnlinePay.show()
}

const showBtnCancel = (order) => {
    return !order.isCancelled
}

const onBtnCancelClick = (order, refDialogCancelOrder, emit) => {
    refDialogCancelOrder.show(order.id, () => {
        emit('requireUpdate')
    })
}

export const useOrdersTable = () => {
    return {
        tableData,
        onlinePay,
        onBtnPayClick,
        showBtnCancel,
        onBtnCancelClick
    }
}
