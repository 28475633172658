import { useAuth } from "@/features/auth/useAuth";
import { computed } from "vue";
import { get } from 'lodash';

const auth = useAuth()

const onBtnLogoutClick = () => {
  auth.logout()
}

const title = computed(() => {
  if (auth.loading.value) return 'Загрузка...'
  const name = get(auth, 'user.value.firstName', '')
  const surname = get(auth, 'user.value.lastName', '')
  if (!name && !surname) return 'Пользователь без имени'
  return name + ' ' + surname
})

export const stateCurrentProfile = () => {
  return {
    onBtnLogoutClick,
    title,
  }
}
