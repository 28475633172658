import PageRetailBranding from "@/features/retail/branding/PageRetailBranding.vue";
import PageRetailMargins from "@/features/retail/margins/PageRetailMargins.vue";
import PageRetailMarginNew from "@/features/retail/margins/PageRetailMarginNew.vue";
import PageRetailDisable from "@/features/retail/modeSwitch/PageRetailDisable.vue";

export const retail = [
  {
    path: '/retail/margins',
    name: 'retail.margins.representation',
    component: PageRetailMargins,
    meta: {
      navTitle: 'Ценообразование',
    },
  },
  {
    path: '/retail/margins/new',
    name: 'retail.margins.create',
    component: PageRetailMarginNew,
    meta: {
      navTitle: 'Создание наценки',
    },
  },
  {
    path: '/retail/branding',
    name: 'retail.branding',
    component: PageRetailBranding,
    meta: {
      navTitle: 'Брендирование',
    },
  },
  {
    path: '/retail/disable',
    name: 'retail.disable',
    component: PageRetailDisable,
    meta: {
      navTitle: 'Отключить "Клиент рядом"',
    },
  },
]
