import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-444a9339"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "square"
};
const _hoisted_2 = {
  class: "icon-wrapper"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  key: 0,
  class: "active-badge"
};
const _hoisted_5 = {
  class: "expanded-part"
};
const _hoisted_6 = {
  class: "children"
};
const _hoisted_7 = {
  class: "label"
};
const _hoisted_8 = {
  key: 0
};
import CategoryTreeAsidePopup from "@/features/catalog/itemsRepresentation/CategoryTreeAsidePopup.vue";
import { computed } from "vue";
import { Icon } from "@iconify/vue";
import { useRoute } from "vue-router";
export default {
  __name: 'AsideMenuElement',
  props: {
    'title': String,
    'icon': String,
    'link': Object,
    'children': Array,
    'popover': Boolean
  },

  setup(__props) {
    const props = __props;
    const route = useRoute();
    const hasChildren = computed(() => {
      if (!props.children) return false;
      return props.children?.length > 0;
    });
    const isActive = computed(() => {
      if (route.path === props.link) return true;

      for (const c of props.children) {
        if (route.path === c.link) return true;
      }

      return false;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      const _component_el_popover = _resolveComponent("el-popover");

      return _openBlock(), _createBlock(_component_router_link, {
        to: props.link,
        class: _normalizeClass({
          'user-aside-menu-element': true,
          isActive: _unref(isActive)
        })
      }, {
        default: _withCtx(() => [_createVNode(_component_el_popover, {
          placement: "right-start",
          disabled: !_unref(hasChildren) && !props.popover
        }, {
          reference: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(Icon), {
            icon: props.icon,
            class: "icon"
          }, null, 8, ["icon"]), _unref(hasChildren) ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 0,
            icon: "bx:chevron-right",
            class: "icon-has-children"
          })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, _toDisplayString(props.title), 1), _unref(isActive) ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true)])]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.children, child => {
            return _openBlock(), _createBlock(_component_router_link, {
              to: child.link,
              class: "child",
              key: child.title
            }, {
              default: _withCtx(() => [_createVNode(_unref(Icon), {
                icon: child.icon,
                class: "img"
              }, null, 8, ["icon"]), _createElementVNode("div", _hoisted_7, _toDisplayString(child.title), 1)]),
              _: 2
            }, 1032, ["to"]);
          }), 128))])]), props.popover && props.title === 'Каталог' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(CategoryTreeAsidePopup, {
            expandedIds: [40]
          })])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["disabled"])]),
        _: 1
      }, 8, ["to", "class"]);
    };
  }

};