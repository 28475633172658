import PageLegals from "@/features/legals/PageLegals.vue";
import PageLegalsEdit from "@/features/legals/PageLegalsEdit.vue";

export const legals = [
  {
    path: '/legals',
    name: 'legals.representation',
    component: PageLegals,
    meta: {
      navTitle: 'Юр. лица',
    },
  },
  {
    path: '/legals/:id',
    name: 'legals.detailed',
    component: PageLegalsEdit,
    meta: {
      navTitle: 'Редактирование юр. лица ',
    },
  }
]
