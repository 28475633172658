import PageSearch from "@/features/search/PageSearch.vue";

export const search = [
  {
    name: 'search.representation',
    path: '/search',
    component: PageSearch,
    meta: {
      navTitle: 'Поиск ',
    },
  }
]
