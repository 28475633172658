import PageNotFound from "./PageNotFound";

export const pageNotFound = [
  {
    path: '/:pathMatch(.*)',
    name: 'pageNotFound',
    component: PageNotFound,
    meta: {
      navTitle: '404',
    },
  },
]
