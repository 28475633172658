import { ref } from "vue";

const priceType = ref('priceRoz')

export const usePriceBin = () => {
  return {
    priceType,

    get: (priceSet, priceTypeParam) => {
      if (priceSet && priceSet[priceTypeParam ?? priceType.value]) return priceSet[priceTypeParam ?? priceType.value]
      return null
    },
  }
}
