import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-74a8bcf2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "page-basket-print"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "name"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "sub"
};
const _hoisted_8 = {
  class: "total-sum"
};
const _hoisted_9 = {
  class: "content"
};
import PrintHeader from "@/features/print/PrintHeader.vue";
import BundlesPrintable from "@/features/print/BundlesPrintable.vue";
import { useUrlBindingId } from "@/features/urlBinding/useUrlBindingId.js";
import { computed, ref, watch } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { moneyFormat, sleep } from "@/features/utils/utils.js";
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { useRetailBranding } from "@/features/retail/branding/useRetailBranding.js";
import { useRetail } from "@/features/retail/useRetail.js";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
import { CONFIG } from "@/config.js";
import { get } from 'lodash-es';
export default {
  __name: 'PageBasket_Print',

  setup(__props) {
    const entityBundle = useEntity('Bundle');
    const basketBind = useUrlBindingId('Basket');
    const bundles = ref(null);
    const priceBin = usePriceBin();
    const retailBranding = useRetailBranding();
    const retailMode = useRetail();
    watch(basketBind.entity, async basket => {
      const res = await entityBundle.list({
        filter: {
          basketId: basket.id
        }
      });
      bundles.value = res.result;
    });
    const ready = computed(() => {
      return basketBind.entity.value !== null && bundles.value !== null;
    });
    watch(ready, async r => {
      if (r) {
        useNavWatcher().title.value = content.title.value;
        await sleep(500);
        window.print();
      }
    });
    const content = {
      title: computed(() => {
        if (!basketBind?.entity?.value) return 'Пожалуйста подождите...';
        return basketBind?.entity?.value?.title ?? 'Предварительный заказ №' + basketBind?.entity?.value?.id;
      })
    };

    const thisMoneyFormat = (money, options) => moneyFormat(money, options);

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PrintHeader, {
        class: "screen-header",
        ready: _unref(ready)
      }, null, 8, ["ready"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString(content.title), 1)]), _unref(retailMode).isEnabled.value ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "logo",
        src: _unref(get)(_unref(retailBranding), 'logoUrl.value')
      }, null, 8, _hoisted_5)) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "logo",
        src: _unref(CONFIG).print.logoPath
      }, null, 8, _hoisted_6))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, "Предварительный заказ №: " + _toDisplayString(_unref(get)(_unref(basketBind), 'entity.value.ud')), 1), _createElementVNode("div", _hoisted_8, "Общая сумма заказа: " + _toDisplayString(thisMoneyFormat(_unref(priceBin).get(_unref(get)(_unref(basketBind), 'entity.value.price')))), 1)]), _createElementVNode("div", _hoisted_9, [bundles.value ? (_openBlock(), _createBlock(BundlesPrintable, {
        key: 0,
        bundles: bundles.value
      }, null, 8, ["bundles"])) : _createCommentVNode("", true)])]);
    };
  }

};