import { useEntity } from "@/features/api/useEntity.js";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export const useUrlBindingId = (entityName) => {
  const route = useRoute()
  const initialRouteName = route.name
  if (!initialRouteName) throw Error("In order to use useUrlBindingId, you must specify a route name")

  const e = useEntity(entityName)
  const entity = ref(null)

  const load = async () => {
    const res = await e.get(route.params?.id)
    entity.value = res.result
  }

  watch(route, (r) => {
    if (r.name === initialRouteName) load() // При смене ID в URL
  })
  load() // При первой инициализации

  return {
    entity,
    load,
    loading: e.loading,
  }
}
