import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5cda2f93"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "news-grid"
};
const _hoisted_2 = {
  class: "news-element"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "date"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = ["innerHTML"];
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import { ref, computed, watch, reactive } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { useRoute, useRouter } from "vue-router";
import { get } from 'lodash-es';
export default {
  __name: 'PageNews',

  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const entityNews = useEntity('News');
    const news = ref([]);
    const pagination = reactive({
      total: 0,
      size: 10
    });

    const loadNews = async p => {
      const res = await entityNews.list({
        page: p ?? page.value ?? 1,
        size: pagination.size ?? 5
      });
      news.value = res.result;
      pagination.total = res.count;
    };
    /* Пагинация */


    const page = computed(() => route.query?.page ?? null);
    watch(page, p => {
      loadNews(p);
    }, {
      immediate: true
    });
    const currentPage = computed(() => Number(route?.query?.page ?? 1));
    const hash = ref(route.hash === '#');

    const onPageChange = newPage => {
      const q = route.query;
      q.page = newPage;
      hash.value = !hash.value;
      router.replace({
        path: route.path,
        query: q,
        hash: hash.value ? '#' : '' // Иначе vue router не перезагружает страницу

      }).catch(() => {});
    };

    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");

      const _component_router_link = _resolveComponent("router-link");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, {
        class: "page-news"
      }, {
        default: _withCtx(() => [_createVNode(PageHeader), _createVNode(_component_el_pagination, {
          class: "pagination",
          layout: "prev, pager, next",
          total: pagination.total,
          onCurrentChange: onPageChange,
          "page-size": pagination.size,
          "onUpdate:page-size": _cache[0] || (_cache[0] = $event => pagination.size = $event),
          currentPage: _unref(currentPage),
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => _isRef(currentPage) ? currentPage.value = $event : null)
        }, null, 8, ["total", "page-size", "currentPage"]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(news.value, n => {
          return _openBlock(), _createBlock(_component_router_link, {
            to: {
              name: 'news.detailed',
              params: {
                id: n.id
              }
            },
            key: n.id
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(get)(n, 'photoMain') ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "img",
              src: _unref(get)(n, 'photoMain'),
              alt: ""
            }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(get)(n, 'date')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(get)(n, 'title')), 1), _createElementVNode("div", {
              class: "description",
              innerHTML: _unref(get)(n, 'description')
            }, null, 8, _hoisted_6)])]),
            _: 2
          }, 1032, ["to"]);
        }), 128))])), [[_directive_loading, _unref(entityNews).loading.value]])]),
        _: 1
      });
    };
  }

};