import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import TableUsers from "@/features/users/representation/TableUsers.vue";
export default {
  __name: 'PageUsers',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_createVNode(PageHeader), _createVNode(TableUsers, {
          actionable: true
        })]),
        _: 1
      });
    };
  }

};