import { computed, ref, watch } from "vue"
import { useEntity } from "@/features/api/useEntity.js";
import { useAuth } from "@/features/auth/useAuth.js";

const entityMenuItem = useEntity('MenuItem')

const items = ref([])

const loading = ref(false)

const auth = useAuth()
watch(auth.user, () => update())

// Инициализация при первом использовании
Promise.resolve().then(async () => {
  await update()
})

const update = async () => {
  try {
    if (!auth.user.value) return
    loading.value = true
    const res = await entityMenuItem.list()
    for (const r of res.result) {
      r.isInternal = computed(() => r.link.startsWith('/'))
    }
    items.value = res.result
  } finally {
    loading.value = false
  }
}

export const useUserPages = () => {
  return {
    items,
    loading,

    itemsHeader: computed(() => items.value.filter(item => item.placement === 'header')),
    itemsFooter: computed(() => items.value.filter(item => item.placement === 'footer')),
  }
}
