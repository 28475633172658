import PageInvoiceDetailed from "@/features/invoice/detailed/PageInvoiceDetailed.vue";

export const invoice = [
  {
    path: '/invoice/:type/:id',
    name: 'invoice.detailed',
    component: PageInvoiceDetailed,
    meta: {
      layout: 'LayoutPrint',
      navTitle: 'Счёт заказа №',
    },
  },
]
