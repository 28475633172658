import { computed } from "vue";
import { usePriceBin } from "@/features/prices/usePriceBin";
import { moneyFormat } from "@/features/utils/utils";
import { useBasket } from "@/features/baskets/useBasket";
import router from '@/router'
import { get } from 'lodash-es';

const basket = useBasket();

const curBasket = computed(() => {
  return baskets.value.find(b => b.id === basket.curBasketId.value) ?? null
})

const priceBin = usePriceBin()
const baskets = computed(() => {
  const bs = get(basket, 'baskets.value', [])
  return bs.map((b) => {
    return {
      id: b.id,
      title: get(b, 'title', "Заказ №" + b.id),
      subtitle: moneyFormat(priceBin.get(b.price)),
    }
  }) ?? []
})

const createBasket = async () => {
  await basket.createBasket()
}

const setCurrentBasketId = (basketId) => {
  basket.setCurrentBasketId(basketId)
}

const onCheckoutClicked = (hideBasket) => {
  if (!canCheckout.value) return
  if (hideBasket) hideBasket();
  router.push({
    name: 'orders.create',
    query: {
      basketId: basket.curBasketId.value,
    }
  })
}

const deleteBasket = (basketId) => {
  basket.deleteBasket(basketId)
}

const canCheckout = computed(() => {
  const curBasket = get(basket, 'baskets.value', []).find((b) => b.id === basket.curBasketId.value)
  return (priceBin.get(curBasket?.price)?.value ?? 0) > 0
})

export const stateBasketSelector = () => {
  return {
    basket,
    curBasket,
    baskets,
    createBasket,
    setCurrentBasketId,
    onCheckoutClicked,
    deleteBasket,
    canCheckout,
  }
}
