import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0e018bfa"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "more"
};
import BottomMenuMoreElement from "@/features/layoutStandart/BottomMenuMoreElement";
import { unlockBody } from "@/features/utils/utils";
export default {
  __name: 'BottomMenuMore',
  props: {
    'elements': Array
  },

  setup(__props) {
    const props = __props;

    const hideMoreMenu = evt => {
      evt.target.closest('.more').classList.remove('show');
      unlockBody();
    };

    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "close",
        onClick: hideMoreMenu
      }), _createVNode(_component_el_menu, {
        class: "menu"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.elements, (element, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: element.title
          }, [_createVNode(_unref(BottomMenuMoreElement), {
            index: index,
            title: element.title,
            icon: element.icon,
            link: element.link,
            "hidden-on-mobile": element.hiddenOnMobile,
            children: element?.children ?? []
          }, null, 8, ["index", "title", "icon", "link", "hidden-on-mobile", "children"])]);
        }), 128))]),
        _: 1
      })]);
    };
  }

};