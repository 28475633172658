import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from "@/features/auth/useAuth.js";
import { auth } from "@/features/auth/routes.js";
import { dashboard } from "@/features/dashboard/routes.js";
import { catalog } from "@/features/catalog/routes.js";
import { baskets } from "@/features/baskets/routes.js";
import { orders } from "@/features/orders/routes.js";
import { userPages } from "@/features/userPages/routes.js";
import { news } from "@/features/news/routes.js";
import { documents } from "@/features/documents/routes.js";
import { item } from "@/features/item/routes.js";
import { manageUsers } from "@/features/users/routes.js";
import { retail } from "@/features/retail/routes.js";
import { notices } from "@/features/notices/routes.js";
import { favorites } from "@/features/favorites/routes.js";
import { pageNotFound } from "@/features/pageNotFound/routes.js";
import { legals } from "@/features/legals/routes.js";
import { search } from "@/features/search/routes.js";
import { itemSet } from "@/features/itemSet/routes.js";
import { mailings } from "@/features/mailings/routes.js";
import { acts } from "@/features/acts/routes.js";
import { invoice } from "@/features/invoice/routes.js";

const authModule = useAuth()

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: () => {
      if (authModule?.user.value === null) return {name: 'auth.login'}
      return {name: 'dashboard'}
    }
  },
  ...auth,
  ...dashboard,
  ...catalog,
  ...item,
  ...baskets,
  ...orders,
  ...userPages,
  ...news,
  ...documents,
  ...manageUsers,
  ...retail,
  ...notices,
  ...favorites,
  ...legals,
  ...search,
  ...itemSet,
  ...mailings,
  ...acts,
  ...invoice,

  ...pageNotFound,
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes,
});

export default router;
