import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { Icon } from "@iconify/vue";
import { lockBody } from "@/features/utils/utils";
export default {
  __name: 'BasketIcon',

  setup(__props) {
    const showBasket = evt => {
      const target = evt.target.closest('.header').querySelector('.basket-selector');
      target.classList.add('show');
      lockBody(target);
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "basket-icon",
        onClick: showBasket
      }, [_createVNode(_unref(Icon), {
        class: "icon",
        icon: "bx:basket"
      })]);
    };
  }

};