import PageFavorites from "@/features/favorites/representation/PageFavorites.vue"

export const favorites = [
  {
    path: '/favorites',
    name: 'favorites.representation',
    component: PageFavorites,
    meta: {
      navTitle: 'Избранное',
    },
  }
]
