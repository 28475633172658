import { useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints({
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
})

export const viewports = {
    xs: breakpoints.greater('xs'),
    sm: breakpoints.greater('sm'),
    md: breakpoints.greater('md'),
    lg: breakpoints.greater('lg'),
    xl: breakpoints.greater('xl'),
}
