import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { Icon } from "@iconify/vue";
import { lockBody } from "@/features/utils/utils";
export default {
  __name: 'SearchIcon',

  setup(__props) {
    const showSearch = evt => {
      const target = evt.target.closest('.header').querySelector('.search');
      target.classList.add('show');
      lockBody(target);
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "search-icon",
        onClick: showSearch
      }, [_createVNode(_unref(Icon), {
        class: "icon",
        icon: "ic:twotone-search"
      })]);
    };
  }

};