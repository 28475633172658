import PageNotices from '@/features/notices/representation/PageNotices';

export const notices = [
  {
    path: '/notices',
    name: 'notices.representation',
    component: PageNotices,
    meta: {
      navTitle: 'Уведомления',
    },
  }
]
