import { ref, watch } from "vue"
import { useHeader } from "@/features/layoutStandart/useHeader.js";
import { useRetailBranding } from "@/features/retail/branding/useRetailBranding.js";
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { useThemes } from "@/features/themes/useThemes.js";
import { serviceNoticeReceiver } from "@/features/notices/serviceNoticeReceiver.js";

const SESSION_STORAGE_KEY = 'retail.isEnabled'

const isEnabled = ref(sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true')
watch(isEnabled, (isEnabled) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, isEnabled)
})

const retailBranding = useRetailBranding()

const header = useHeader()
const themeModule = useThemes()

watch([isEnabled, retailBranding.logoUrl, retailBranding.themeKey], ([isEnabled, logoUrl, themeKey]) => {
  // Смена логотипа
  if (isEnabled && retailBranding.logoUrl) {
    header.logoUrl.value = logoUrl
  } else {
    header.logoUrl.value = null
  }

  // Смена темы
  if (isEnabled && themeKey) {
    themeModule.activeThemeKey.value = themeKey
  } else {
    themeModule.activeThemeKey.value = null
  }

  // Смена цен
  if (isEnabled) usePriceBin().priceType.value = 'priceRoz'
  else usePriceBin().priceType.value = 'priceOpt'

  // Смена показа уведомлений при получении новых сообщений
  serviceNoticeReceiver().showNotifications.value = !isEnabled
})

export const useRetail = () => {
  return {
    isEnabled,
    enable: () => isEnabled.value = true,
    disable: () => isEnabled.value = false,
  }
}
