import PageUserPage from "@/features/userPages/PageUserPage.vue"

export const userPages = [
  {
    path: '/page/:name',
    name: 'userPages.detailed',
    component: PageUserPage,
    meta: {
      navTitle: 'Загрузка...',
    }
  }
]
