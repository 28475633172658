import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useLayoutWatcher } from "@/features/layoutWatcher/serviceLayoutWatcher.js";
import { useAuth } from "@/features/auth/useAuth.js";
import { useAuthWall } from "@/features/auth/serviceAuthWall.js";
import ru from 'element-plus/es/locale/lang/ru';
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
import { usePermissions } from "@/features/permissions/usePermissions.js";
export default {
  __name: 'App',

  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const elementPlusConfig = {
      locale: ru
    }; // Инициализация сервисов

    useNavWatcher(route);
    const layoutWatcher = useLayoutWatcher(route);
    useAuthWall(router, useAuth()); // eslint-disable-next-line no-unused-vars

    const permisions = usePermissions();
    return (_ctx, _cache) => {
      const _component_el_config_provider = _resolveComponent("el-config-provider");

      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: elementPlusConfig.locale
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(_unref(layoutWatcher).layout.value), null, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
          _: 3
        }))]),
        _: 3
      }, 8, ["locale"]);
    };
  }

};