import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import ItemsListShow from "@/features/catalog/itemsRepresentation/ItemsListShow.vue";
import ItemsListShow_mobile from "@/features/catalog/itemsRepresentation/ItemsListShow_mobile.vue";
import SortSelector from "@/features/catalog/itemsRepresentation/SortSelector.vue";
import FilterSelector from "@/features/catalog/itemsRepresentation/FilterSelector.vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import TableViewSwitcher from "@/features/catalog/itemsRepresentation/TableViewSwitcher.vue";
import ItemsTileShow from "@/features/catalog/itemsRepresentation/ItemsTileShow.vue";
import { computed, onMounted, watch } from "vue";
import { statePageCatalogItems } from "@/features/catalog/itemsRepresentation/statePageCatalogItems.js";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
import { get } from 'lodash-es';
import { viewports } from "@/features/utils/viewports";
import { CONFIG } from "@/config";
export default {
  __name: 'PageCatalogItems',

  setup(__props) {
    onMounted(() => {
      state.loadCategories();
    });
    const state = statePageCatalogItems();
    const loading = state.loading;
    const navWatcher = useNavWatcher();
    watch([state.curCatalogCategory], ([curCat]) => {
      if (curCat?.title) navWatcher.title.value = curCat?.title;
    }, {
      immediate: true
    });
    const subcategories = computed(() => {
      if (CONFIG.catalog.showSubcategories) {
        return state.categories.value.filter(c => c.parent === state.curCatalogCategory.value.id);
      } else {
        return false;
      }
    });
    const breadcrumb = computed(() => {
      let res = [];
      let babyMammoth = state.curCatalogCategory.value;
      /* eslint-disable-next-line */

      while (true) {
        if (babyMammoth === null) break;
        let mama = state.categories.value.find(c => babyMammoth?.parent === c.id) ?? null;
        if (mama === null) break;
        res = [{
          text: mama.title,
          to: {
            name: 'catalog.items',
            params: {
              categoryId: mama.id
            }
          }
        }, ...res];
        babyMammoth = mama;
      }

      return [{
        text: 'Каталог',
        to: {
          name: 'catalog'
        }
      }, ...res];
    });
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_createVNode(PageHeader, {
          title: _unref(get)(_unref(state), 'curCatalogCategory.value.title', null),
          loading: _unref(get)(_unref(state), 'curCatalogCategory.value.title', null) === null,
          breadcrumb: _unref(breadcrumb),
          style: {
            "margin-bottom": "5px"
          }
        }, null, 8, ["title", "loading", "breadcrumb"]), _unref(subcategories) ? (_openBlock(), _createBlock(_component_el_breadcrumb, {
          key: 0,
          separator: "-",
          class: "subcategories"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(subcategories), c => {
            return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
              class: "item",
              key: c.id,
              to: {
                name: 'catalog.items',
                params: {
                  categoryId: c.id
                }
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(c.title), 1)]),
              _: 2
            }, 1032, ["to"]);
          }), 128)), _createVNode(_component_el_breadcrumb_item, {
            class: "item"
          })]),
          _: 1
        })) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_el_main, {
          class: "main"
        }, {
          default: _withCtx(() => [_createVNode(SortSelector, {
            class: "sort-selector",
            sortings: _unref(state).possibleSorts.value,
            onSortSelected: _unref(state).onSortSelected,
            selected: _unref(state).activeSorts.value
          }, null, 8, ["sortings", "onSortSelected", "selected"]), _createVNode(FilterSelector, {
            class: "filter-selector",
            filters: _unref(state).possibleFilters.value,
            onFilterSelected: _unref(state).onFilterSelected,
            selected: _unref(state).activeFilters.value
          }, null, 8, ["filters", "onFilterSelected", "selected"]), _createVNode(TableViewSwitcher, {
            modelValue: _unref(state).itemsShow.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(state).itemsShow.value = $event)
          }, null, 8, ["modelValue"]), _createVNode(_component_el_container, {
            class: "pagination top"
          }, {
            default: _withCtx(() => [_unref(state).paginationMode.value !== 'more' ? (_openBlock(), _createBlock(_component_el_pagination, {
              key: 0,
              class: "pagination__element",
              currentPage: _unref(state).currentPage.value,
              "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => _unref(state).currentPage.value = $event),
              "page-size": _unref(state).currentSize.value,
              "onUpdate:page-size": _cache[2] || (_cache[2] = $event => _unref(state).currentSize.value = $event),
              "page-sizes": _unref(state).pageSizes,
              total: _unref(state).totalElements.value,
              layout: `${_unref(viewports).xs.value ? 'total' : ''}, sizes, prev, pager, next`,
              "pager-count": !_unref(viewports).xs.value ? 3 : 7,
              small: !_unref(viewports).sm.value,
              "hide-on-single-page": "",
              onCurrentChange: _unref(state).onPageChange,
              onSizeChange: _unref(state).onSizeChange
            }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "layout", "pager-count", "small", "onCurrentChange", "onSizeChange"])) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_container, {
            class: "items"
          }, {
            default: _withCtx(() => [_unref(viewports).lg.value && _unref(state).itemsShow.value === 'list' ? (_openBlock(), _createBlock(ItemsListShow, {
              key: 0,
              items: _unref(state).items.value,
              onRequireUpdate: _unref(state).loadItems
            }, null, 8, ["items", "onRequireUpdate"])) : _createCommentVNode("", true), !_unref(viewports).lg.value && _unref(state).itemsShow.value === 'list' ? (_openBlock(), _createBlock(ItemsListShow_mobile, {
              key: 1,
              items: _unref(state).items.value,
              onRequireUpdate: _unref(state).loadItems
            }, null, 8, ["items", "onRequireUpdate"])) : _createCommentVNode("", true), _unref(state).itemsShow.value === 'grid' ? (_openBlock(), _createBlock(ItemsTileShow, {
              key: 2,
              items: _unref(state).items.value
            }, null, 8, ["items"])) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_container, {
            class: "pagination bottom"
          }, {
            default: _withCtx(() => [_unref(state).paginationMode.value !== 'page' ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_unref(state).canLoadMore.value ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              onClick: _unref(state).onMoreClick,
              type: "primary",
              plain: "",
              style: {
                "width": "100%"
              }
            }, {
              default: _withCtx(() => [_createTextVNode("Загрузить ещё (показано " + _toDisplayString(_unref(state).items.value.length) + " из " + _toDisplayString(_unref(state).totalElements.value) + ")", 1)]),
              _: 1
            }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "default",
              plain: "",
              disabled: "",
              style: {
                "width": "100%"
              }
            }, {
              default: _withCtx(() => [_createTextVNode("Все товары показаны (" + _toDisplayString(_unref(state).totalElements.value) + " шт.)", 1)]),
              _: 1
            }))], 64)) : _createCommentVNode("", true), _unref(state).paginationMode.value !== 'more' ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [_unref(state).paginationMode.value !== 'more' ? (_openBlock(), _createBlock(_component_el_pagination, {
              key: 0,
              class: "pagination__element",
              currentPage: _unref(state).currentPage.value,
              "onUpdate:currentPage": _cache[3] || (_cache[3] = $event => _unref(state).currentPage.value = $event),
              "page-size": _unref(state).currentSize.value,
              "onUpdate:page-size": _cache[4] || (_cache[4] = $event => _unref(state).currentSize.value = $event),
              "page-sizes": _unref(state).pageSizes,
              total: _unref(state).totalElements.value,
              layout: `${_unref(viewports).xs.value ? 'total' : ''}, sizes, prev, pager, next`,
              "pager-count": !_unref(viewports).xs.value ? 3 : 7,
              small: !_unref(viewports).sm.value,
              "hide-on-single-page": "",
              onCurrentChange: _unref(state).onPageChange,
              onSizeChange: _unref(state).onSizeChange
            }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "layout", "pager-count", "small", "onCurrentChange", "onSizeChange"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        })), [[_directive_loading, _unref(loading).item]])]),
        _: 1
      });
    };
  }

};