import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-f634a6fa"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bottom-menu"
};
const _hoisted_2 = {
  class: "elements"
};
import BottomMenuElement from "@/features/layoutStandart/BottomMenuElement";
import BottomMenuMore from "@/features/layoutStandart/BottomMenuMore";
import { useNavMenu } from "@/features/layoutStandart/useNavMenu.js";
import { get } from 'lodash-es';
export default {
  __name: 'BottomMenu',

  setup(__props) {
    const navMenu = useNavMenu();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(get)(_unref(navMenu), 'elements.value', []), element => {
        return _openBlock(), _createBlock(_unref(BottomMenuElement), {
          key: element.title,
          title: element.title,
          icon: element.icon,
          link: element.link,
          "hidden-on-mobile": element.hiddenOnMobile,
          children: element?.children ?? []
        }, null, 8, ["title", "icon", "link", "hidden-on-mobile", "children"]);
      }), 128)), _createVNode(_unref(BottomMenuMore), {
        elements: _unref(get)(_unref(navMenu), 'elements.value', [])
      }, null, 8, ["elements"])])]);
    };
  }

};