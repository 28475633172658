import { get } from "lodash-es";
import { usePriceBin } from "@/features/prices/usePriceBin";
import { computed, watch } from "vue";
import {useBackend} from "@/features/api/useBackend";

const backend = useBackend();

export const appLog = (...args) => {
  console.log('PL_B2B', ...args)
}

export const normalizePhotoUrl = (url) => {
  return url
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getReadableSize = (bytes, dp) => {
  const si = true
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}


export const currencyToLocale = (currency) => {
  const c = currency.toLowerCase()
  if (c === 'rub') return 'ru-RU'
  if (c === 'usd') return 'en-US'
  if (c === 'eur') return 'de-DE'
  if (c === 'byn') return 'by-BY'
  return 'ru-RU'
}

export const moneyFormat = (money, options = {
  valueMultiplier: 1,
}) => {
  if ((money ?? null) === null) return null
  if ((money?.value ?? null) === null) return null
  if ((money?.currency ?? null) === null) return null

  const formatter = new Intl.NumberFormat(currencyToLocale(money.currency), {
    style: 'currency',
    currency: money.currency,
  })
  return formatter.format(money.value * options.valueMultiplier)
}

/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний
 * @param  iNumber Integer Число на основе которого нужно сформировать окончание
 * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * @return String
 */

export const getNumEnding = (iNumber, aEndings) => {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case (1):
        sEnding = aEndings[0];
        break;
      case (2):
      case (3):
      case (4):
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
}

// Добавляет темный задний фон и запрещает скролл на сайте
export const lockBody = (target, isMoreMenuOpen) => {
  const body = document.querySelector('body');
  body.classList.add('lock');

  if (!isMoreMenuOpen) {
    const bottomMenu = document.querySelector('.bottom-menu');
    if (bottomMenu) bottomMenu.style.zIndex = '5';
  }

  const blackout = document.querySelector('.blackout');
  blackout.style.display = 'block';
  setTimeout(() => {
    blackout.classList.add('active');
  }, 200);

  blackout.addEventListener('click', () => {
    target.classList.remove('show');
    unlockBody();
  })
}

// Уберает темный задний фон и разрешает скролл на сайте
export const unlockBody = () => {
  const body = document.querySelector('body');
  body.classList.remove('lock');

  const bottomMenu = document.querySelector('.bottom-menu');
  const blackout = document.querySelector('.blackout');
  
  blackout.classList.remove('active');
  setTimeout(() => {
    blackout.style.display = 'none';

    if (bottomMenu) {
      bottomMenu.style.zIndex = '300';
    }
  }, 200);
}

// Cоздает объект для таблицы товаров в каталоге
export const createTableData = (items) => {
  return items.map((item) => {
    let availability = {
      quantity: 0,
      unit: get(item, 'offers[0].unit', ''),
    };

    for (const offer of get(item, 'offers', [])) {
      availability.quantity += Number(offer.balance);
    }
    let price = moneyFormat(usePriceBin().get(item?.defaultPrice, 'priceOpt'))
    let retailPrice = moneyFormat(usePriceBin().get(item?.defaultPrice, 'priceRoz'))

    return {
      ...item,
      priceDisplay: price,
      retailPriceDisplay: retailPrice,
      availability: availability,
      actions: item.actions,
      isFavorite: item.isFavorite,
    }
  }) ?? []
}

// Получает правильное наличие для товаров
export const getBalanceForProducts = (items) => {
  // Получение id офферов для отправки на бэк, чтобы получить правильное наличие
  const itemsId = computed(() => {
    const arr = [];
    items.map((item) => {
      item.offers?.map((offer) => {
        arr.push(offer.id)
      })
    })

    return arr;
  })

  // Подмена неправильного наличия на правильное
  watch(itemsId, async (currentValue) => {
    // Не отправлять запрос, если нет товаров
    if (items.length > 0) {
      // Получение правильного наличия по id`шникам
      const res = await backend.api('catalog/GetBalanceForProducts', {
        idProducts: currentValue,
      });
      const itemsOffersBalance = res?.data?.result?.offers;

      // Подмена поля balance в offers
      items.map((item) => {
        item.offers?.map((offer) => {
          itemsOffersBalance.forEach((balanceInfo) => {
            if (Number(balanceInfo.id) === Number(offer.id)) {
              offer.balance = balanceInfo.balance;
            }
          })
        })
      })
    }
  }, {immediate: true})
}
