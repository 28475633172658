import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-39721f19"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "availability yes"
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  key: 1,
  class: "availability no"
};
const _hoisted_4 = {
  class: "text"
};
import { Icon } from "@iconify/vue";
import { CONFIG } from "@/config.js";
import { viewports } from "@/features/utils/viewports";
export default {
  __name: 'ItemAvailability',
  props: {
    'availability': Object
  },

  setup(__props) {
    const props = __props;
    const text = {
      yes: 'В наличии',
      no: 'Под заказ'
    };
    return (_ctx, _cache) => {
      return props.availability.quantity > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_unref(viewports).xl.value ? (_openBlock(), _createBlock(_unref(Icon), {
        key: 0,
        icon: "ci:check-bold",
        class: "icon"
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(CONFIG).warehouses.showQuantity ? props.availability.quantity + ' ' + props.availability.unit : text.yes), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_unref(viewports).xl.value ? (_openBlock(), _createBlock(_unref(Icon), {
        key: 0,
        icon: "fluent:call-48-filled",
        class: "icon"
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, _toDisplayString(text.no), 1)]));
    };
  }

};