export const CONFIG = {

  auth: {
    loginLogoPath: require('@/assets/config/logo.svg'),
    register: {
      subtitle: 'Заявку рассмотрит наш менеджер и отправит Вам на почту результат проверки.',
      hint: 'Проблемы с регистрацией? Напишите нам на&nbsp;<a href="mailto:b2b@example.com" target="_blank">b2b@example.com</a>',
      checkboxes: [
        /** ID должен начинаться с префикса cb_ */
        {
          id: 'cb_privacy',
          html: 'Согласен на&nbsp;<a href="//intervolga.ru" target="_blank">обработку персональных данных</a>'
        },
      ],
    },
  },

  layoutStandart: {
    headerContacts: [
        '+7 (707) 780 78 70',
        '+7 (727) 325 99 90',
        'MaygazievaA@chintglobal.com',
    ],
    headerLogoPath: require('@/assets/config/logo.svg'),
  },

  navWatcher: {
    titleSuffix: ' | Chint Electric',
  },

  dashboard: {
    showRightColumn: true,
  },

  catalog: {
    defaultItemView: 'list', // 'list' | 'grid'
    showRetailPrice: false,
    showSubcategories: false,
  },

  print: {
    logoPath: require('@/assets/config/logo.svg'),
  },

  banners: {
    height: '400px',
  },

  global: {
    country: 'RU',
    priceTitle: 'Оптовая цена'
  },

  warehouses: {
    showQuantity: true,
  },

  tables: {
    articleFieldWidth: 100,
    priceFieldWidth: 170,
  },

  usersTable: {
    showINN: true,
  },

  orders: {
    detailed: {
      downloadInvoice: {
        showWithFirstStatus: true,
      }
    }
  }
}
