import PageBasket from "@/features/baskets/PageBasket.vue";
import PageBasket_Print from "@/features/baskets/PageBasket_Print.vue";

export const baskets = [
  {
    path: '/basket/:id',
    name: 'baskets.detailed',
    component: PageBasket,
    meta: {
      navTitle: 'Корзина №',
    }
  },
  {
    path: '/basket/:id/_print',
    name: 'baskets.detailed._print',
    component: PageBasket_Print,
    meta: {
      navTitle: 'Печать корзины №',
      layout: 'LayoutPrint',
    }
  }
]
